angular.module('fingerink')
    .service('addDocumentsCategoryModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/addDocumentsCategoryModal.tpl.html',
                controller: 'addDocumentsCategoryModalCtrl',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('addDocumentsCategoryModalCtrl', function($rootScope, $scope, $uibModalInstance, session, signatureService,swalService) {
        $scope.categorian = {};
        $scope.session = session.get();
        $scope.user = $scope.session.user.id;
        $scope.categorian.user = $scope.user.id;
        $scope.close = function () {
            $uibModalInstance.close(false);
        };

        $scope.anyadirCategoria = function () {
            signatureService.createFirmaCategoria($scope.categorian).then(function (response) {
                    swalService.basicSwal('¡Correcto!', '¡Se ha creado la categoría', 'success');
                    $uibModalInstance.close(true);
            }, function (response) {
                if (response.status === 417) {
                    swalService.basicSwal('Sin permisos', 'Algún dato no es correcto', 'error');
                    $uibModalInstance.close(false);

                } else {
                    swalService.basicSwal('Algo ha ido mal','Ha habido un error al crear la categoría', 'error');
                    $uibModalInstance.close(false);
                }
            });
        };
    });